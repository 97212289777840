import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './footer.scss';
import Dropdown from '../dropdown/dropdown';

export default ({ location }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `}
    render={data => (
      <footer className="footer" id="contatti">
        <div className="footer__content">
          <div className="footer__content-address">
            <GatsbyImage image={getImage(data.logo.childImageSharp)} className="footer__logo" />
            <br />
            <div>
              <strong>Costruzioni Puocci SRL</strong>
            </div>
            <br />
            <div>Via Dell'Olmo, 15</div>
            <div>41030 Sorbara (MO)</div>
          </div>
        </div>

        <div className="footer__footer">
          <div className="footer__footer-content">
            <nav role="navigation">
              <Link
                to="/"
                className={location.hash !== '' && location.pathname === '/' ? 'active' : ''}
              >
                <strong>Home</strong>
              </Link>
              <Link to="/#servizi" className={location.hash === '#servizi' ? 'active' : ''}>
                Servizi
              </Link>
              <Link to="/#chi-siamo" className={location.hash === '#chi-siamo' ? 'active' : ''}>
                <strong>Chi siamo</strong>
              </Link>
              <Link to="/#dove-siamo" className={location.hash === '#dove-siamo' ? 'active' : ''}>
                <strong>Dove siamo</strong>
              </Link>
            </nav>
          </div>
        </div>
      </footer>
    )}
  ></StaticQuery>
);
