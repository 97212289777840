import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './service.scss';

export default ({ service }) => (
  <div className="service">
    <GatsbyImage className="service__image" image={getImage(service.image)} />

    <span className="service__name">{service.name}</span>
  </div>
);
