import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './header.scss';

export default ({ location }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }

        services: allContentfulService {
          edges {
            node {
              name
              slug
            }
          }
        }

        general: contentfulInformazioniGenerali(
          id: { eq: "405fb8e5-f693-5c7f-b506-4b5761d9c5b5" }
        ) {
          email
          numeriDiTelefono
        }
      }
    `}
    render={data => (
      <header>
        <div className="header-wrapper">
          <div className="header__title">
            <Link to="/" className="logo">
              <GatsbyImage
                objectFit="contain"
                className="logo"
                image={getImage(data.logo.childImageSharp)}
              />
            </Link>
            <nav role="navigation">
              <Link
                to="/"
                className={location.hash === '' && location.pathname === '/' ? 'active' : ''}
              >
                <strong>Home</strong>
              </Link>
              <Link className={location.hash === '#servizi' ? 'active' : ''} to="/#servizi">
                <strong>Servizi</strong>
              </Link>
              <Link to="/#chi-siamo" className={location.hash === '#chi-siamo' ? 'active' : ''}>
                <strong>Chi siamo</strong>
              </Link>
              <Link className={location.hash === '#dove-siamo' ? 'active' : ''} to="/#dove-siamo">
                <strong>Dove siamo</strong>
              </Link>
            </nav>
          </div>

          <a
            href={`https://wa.me/39${data.general.numeriDiTelefono[0].replaceAll(' ', '')}`}
            className="header__cta"
          >
            <span>Contattaci</span>
          </a>
        </div>
      </header>
    )}
  ></StaticQuery>
);
