import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import Service from '../components/service/service';
import toast, { Toaster } from 'react-hot-toast';
import { Parallax } from 'react-parallax';

import moment from 'moment';

import './index.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const isBrowser = () => typeof window !== 'undefined';

if (isBrowser()) {
  window.notificationsShow = false;
}

const RootIndex = ({ location, data }) => {
  const siteTitle = get(data, 'site.siteMetadata.title');

  const pageInfo = get(data, 'pageInfo');
  const heroImage = getImage(pageInfo.heroImage);
  const logo = get(data, 'logo');
  const heroServices = get(data, 'heroServices.childImageSharp');
  const services = get(data, 'services.nodes');
  const principalServices = get(data, 'principalServices.nodes');
  const notifiche = get(data, 'general.notifiche');
  const ristrutturazione1 = get(data, 'ristrutturazione1.childImageSharp');
  const ristrutturazione2 = get(data, 'ristrutturazione2.childImageSharp');
  const chiSiamoHero = get(data, 'chiSiamoHero.childImageSharp');

  if (
    isBrowser() &&
    window?.notificationsShow &&
    notifiche.some(
      notifica =>
        moment(notifica.inizio).isBefore(moment()) && moment(notifica.fine).isAfter(moment()),
    )
  ) {
    const notificationsToShow = notifiche.filter(
      notifica =>
        moment(notifica.inizio).isBefore(moment(), 'minutes') &&
        moment(notifica.fine).isAfter(moment(), 'minutes'),
    );

    notificationsToShow.forEach(notification =>
      toast(
        t => (
          <div
            className="notification"
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
          >
            {renderRichText(notification.testo)}
            <button className="notification__cta" onClick={() => toast.dismiss(t.id)}>
              Nascondi
            </button>
          </div>
        ),
        {
          position: 'top-center',
          duration: 5000,
          className: 'container',
        },
      ),
    );

    window.notificationsShow = true;
  }

  return (
    <>
      <Layout location={location}>
        <Helmet title={siteTitle}>
          <meta name="description" content="Costruzioni Puocci SRL" />
        </Helmet>

        <Parallax
          className="parallax"
          bgClassName="full-width-img"
          style={{ overflow: 'unset' }}
          bgImage={heroImage.images.fallback.src}
        >
          <img src={logo.publicURL} style={{ margin: 'auto', maxWidth: '25%', marginTop: '10%' }} />
        </Parallax>

        <div className="page__title-wrapper" id="servizi">
          <h4 className="page__title" style={{ marginBottom: 0 }}>
            Servizi
          </h4>

          <div style={{ display: 'flex' }}>
            <h5 style={{ marginTop: 0 }}>I NOSTRI SERVIZI</h5>
            <hr style={{ width: '70%' }} />
          </div>
        </div>

        <div className="services">
          {principalServices.map(service => (
            <Service key={service.name} service={service} />
          ))}
        </div>

        <h4 className="page__title" style={{ marginLeft: 0 }}>
          Tutti i servizi
        </h4>

        <div className="services__list-wrapper">
          <GatsbyImage image={getImage(heroServices)} className="services__image" />
          <div className="services__list">
            {services.map(service => (
              <span className="services__list-name">{service.name}</span>
            ))}
          </div>
        </div>

        <h4 className="page__title" style={{ marginLeft: 0 }}>
          Ristrutturazioni complete
        </h4>

        <GatsbyImage image={getImage(ristrutturazione1)} style={{ marginBottom: '10px' }} />
        <GatsbyImage image={getImage(ristrutturazione2)} />

        <div className="page__title-wrapper" id="chi-siamo">
          <h4 className="page__title" style={{ marginBottom: 0 }}>
            Chi Siamo
          </h4>

          <div style={{ display: 'flex' }}>
            <h5 style={{ marginTop: 0 }}>LA NOSTRA STORIA</h5>
            <hr style={{ width: '70%' }} />
          </div>
        </div>

        <GatsbyImage image={getImage(chiSiamoHero)} />
        <p className="description">
          L’impresa di Costruzioni Puocci opera nel settore delle costruzioni e ristrutturazioni
          distinguendosi per la qualità dei prodotti e la specializzazione dei collaboratori.
          L’attività si occupa di tutta l’ediliza dalla A alla Z, dai lavori di costruzione di
          immobili civili ed industriali in genere, sia per conto proprio che per conto terzi, ai
          lavori di ristrutturazione e restauro di edifici, alla manutenzione di stabili. Siamo
          inoltre specializzti nella riqualificazioni di edifici per il miglioramento e il risparmio
          energetico
        </p>

        <div className="page__title-wrapper">
          <h4 className="page__title" style={{ marginBottom: 0 }} id="dove-siamo">
            Dove siamo
          </h4>

          <div style={{ display: 'flex' }}>
            <h5 style={{ marginTop: 0 }}>MAPPA</h5>
            <hr style={{ width: '70%' }} />
          </div>
        </div>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2833.6905323356063!2d11.01142981580393!3d44.746330079098996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477feb6a458fec75%3A0x9e7c05a4e1b088c5!2sVia%20dell&#39;Olmo%2C%2015%2C%2041030%20Sorbara%20MO!5e0!3m2!1sit!2sit!4v1645216218704!5m2!1sit!2sit"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </Layout>
      <Toaster position="top-center" />
    </>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }

    general: contentfulInformazioniGenerali(id: { eq: "405fb8e5-f693-5c7f-b506-4b5761d9c5b5" }) {
      notifiche {
        testo {
          raw
        }
        inizio
        fine
      }
    }

    pageInfo: contentfulPaginaWeb(webPageTitle: { eq: "Home" }) {
      heroImage {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    logo: file(relativePath: { eq: "logo-big.svg" }) {
      publicURL
    }

    heroServices: file(relativePath: { eq: "hero-services.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    ristrutturazione1: file(relativePath: { eq: "ristrutturazione-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    ristrutturazione2: file(relativePath: { eq: "ristrutturazione-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    chiSiamoHero: file(relativePath: { eq: "chi-siamo-hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    principalServices: allContentfulService(filter: { principale: { eq: true } }) {
      nodes {
        name
        principale
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }

    services: allContentfulService(filter: { principale: { ne: true } }) {
      nodes {
        name
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;
